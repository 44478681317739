import React, { useEffect, useState } from "react";
import Aos from "aos";
import ProductCardLoader from "../common/loaders/product-card-loader";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import axios from "axios";
import { all_routes } from "../router/all_routes";
import { API_BASE_URL, CLIENT_BASE_PATH } from "../../environment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ReactDatePicker from "react-datepicker";
import PhoneNumberField from "../home/phoneNumberField";
import { setMeta } from "../../core/data/utils/meta";

const HotelTransport = () => {
  const routes = all_routes;
  const [packagesList, setPackagesList] = useState([]);
  const [confirmBookingData, setConfirmBookingData] = useState<any>([]);
  const [isloading, setIsLoading] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [bookingConfirmToggle, setBookingConfirmToggle] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [locationsList, setLocationsList] = useState<any>([]);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  useEffect(() => {
    const canonicalUrl = `${CLIENT_BASE_PATH}/hotel-transport`;
    setMeta(
      "Hotel Transportation Service in Saudi Arabia | Alharamain Cab", "Alharamain Cab of Saudi Arabia's leading transport Company Offer premium and luxury Hotel Transportation Service in Saudi Arabia. Book Online Today!",
      canonicalUrl
    );
  }, []);

  useEffect(() => {
    // Fetch the list of locations on component mount
    const fetchLocationsList = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/locations/list`);
        setLocationsList(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setIsLoading(false);
      }
    };
    
    fetchLocationsList();
  }, []);

  useEffect(() => {
    // Trigger the fetch of packages after locationsList is set
    const fetchPackages = async () => {
      const locationId= locationsList.find((loc:any) => loc.name === "Makkah Hotel")?.id;
      if (locationId) {
        try {
          const response = await axios.post(`${API_BASE_URL}/packages/list`, {
            pickup_location: locationId,
          });
          setPackagesList(response.data);
        } catch (error) {
          console.error("Error fetching packages:", error);
        }
      }
    };

    if (locationsList.length > 0) {
      fetchPackages();
    }
  }, [locationsList]); 

  
  const schemaWithPersonalDetails = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    pickup_date_time1: Yup.string().required("Select Pickup Date Time"),
    phone_no: Yup.string().required("Phone number is required"),
  });

  const {
    handleSubmit: handlePackageSubmit,
    control: handleController,
    formState: { errors: personalDetailsErrors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaWithPersonalDetails),
  });

  const onSubmitPersonalDetails = async (bookingFormData: any) => {
    setIsLoading(true);
    try {
      const payload = {
        package_id: confirmBookingData.id,
        pickup_date_time: bookingFormData.pickup_date_time1,
        phone_no: bookingFormData.phone_no,
        name: bookingFormData.name,
        email: bookingFormData.email,
        user_id: "",
        baby_seat: confirmBookingData.vehicle_detail.baby_seat,
      };
      const response = await axios.post(
        `${API_BASE_URL}/packages/booking`,
        payload
      );
      if (response.status == 201 || response.status == 200) {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage("");
        setSuccessMessage(response.data.message);
        setIsLoading(false);
      } else {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage(response.data.message);
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setSuccessMessage("");
        setIsLoading(false);
      } else {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage("An error occurred. Please try again later.");
        setIsLoading(false);
      }
    } finally {
      setConfirmBookingData([]);
      reset();
    }
  };

  const handleBookingPackages = (bookingData: any) => {
    setConfirmBookingData(bookingData);
    setBookingConfirmToggle(true);
  };
  const closeBookingModal = () => {
    setIsBookingModalOpen(false);
  };
  const closePackagesBooking = () => {
    setBookingConfirmToggle(false);
    setConfirmBookingData([]);
  };


  return (
    <>
    <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Hotel Transport</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Hotel Transport
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

        {/* Booking Status Modal */}
        {isBookingModalOpen && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal fade d-block show`}
          id="delete_modal"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="delete-action">
                  <div className="delete-header">
                    <h4>Booking Status</h4>
                  </div>
                  {isloading && (
                    <p className="py-4">
                      Please wait your booking is in progress ...
                    </p>
                  )}
                  {successMessage && (
                    <p className="py-4 text-success">{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p className="py-4 text-danger">{errorMessage}</p>
                  )}

                  <div className="modal-btn">
                    <div className="row">
                      <div className="col-6 m-auto">
                        <button
                          onClick={closeBookingModal}
                          className="btn btn-primary w-100"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isBookingModalOpen && <div className="modal-backdrop fade show"></div>}
      {/* Packages Booking Modal */}
      {bookingConfirmToggle && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal multi-step fade d-block show`}
          id="complete_booking"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form onSubmit={handlePackageSubmit(onSubmitPersonalDetails)}>
              <div className="modal-content">
                <div className="modal-header" onClick={closePackagesBooking}>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="booking-header">
                    <div className="booking-img-wrap">
                      <div className="book-img">
                        <img
                          src="https://dev.alharamaincab.com/assets/img/cars/carImage.png"
                          alt="img"
                        />
                      </div>
                      <div className="book-info">
                        <h6>{confirmBookingData?.vehicle_detail?.name}</h6>
                        <p>
                          <i className="feather-map-pin" /> From{" "}
                          {confirmBookingData?.pickup_location?.name} {" to "}{" "}
                          {confirmBookingData?.drop_location?.name}
                        </p>
                      </div>
                    </div>
                    <div className="book-amount">
                      <p>Total Amount</p>
                      <h6>{confirmBookingData?.vehicle_detail.price}</h6>
                    </div>
                  </div>
                  <div className="booking-group">
                    <div className="booking-wrapper">
                      <div className="booking-title">
                        <h6>Booking Details</h6>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Booking Type</h6>
                            <p>Package Booking</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Pickup Location</h6>
                            <p>{confirmBookingData?.pickup_location?.name}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>DropOff Location</h6>
                            <p>{confirmBookingData?.pickup_location?.name}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Total Rides</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.total_rides}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Baby Seat</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.baby_seat ===
                              true
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Passenger Capacity</h6>
                            <p>
                              {
                                confirmBookingData?.vehicle_detail
                                  ?.passenger_capacity
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Luggage Capacity</h6>
                            <p>
                              {
                                confirmBookingData?.vehicle_detail
                                  ?.luggage_capacity
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Fuel Type</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.fuel_type}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Pickup Date and Time</h6>
                            <p>{confirmBookingData?.pickup_date_time}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="booking-wrapper">
                      <div className="booking-title">
                        <h6>Personal Details</h6>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="input-block">
                            <label>Name</label>
                            <div className="group-img">
                              <Controller
                                name="name"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.name && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.name.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="input-block">
                            <label>Email</label>
                            <div className="group-img">
                              <Controller
                                name="email"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.email && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="column-group-main">
                            <div className="input-block">
                              <label>Pickup Date Time</label>
                            </div>
                            <div className="input-block-wrapp">
                              <div className="input-block date-widget">
                                <div className="group-img">
                                  <Controller
                                    name="pickup_date_time1"
                                    control={handleController}
                                    render={({ field }) => (
                                      <ReactDatePicker
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        onChange={(date) =>
                                          field.onChange(date)
                                        }
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        placeholderText="Select Date and Time"
                                        className="form-control"
                                        timeInputLabel="Time:"
                                      />
                                    )}
                                  />
                                  {personalDetailsErrors.pickup_date_time1 && (
                                    <p className="text-danger pt-1">
                                      {
                                        personalDetailsErrors.pickup_date_time1
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="search-box-banner p-0 m-0">
                            <div className="input-block column-group-main max-width-100">
                              <label>Phone Number</label>
                              <div className="phone-input-style PhoneInput">
                                <Controller
                                  name="phone_no"
                                  control={handleController}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <PhoneNumberField
                                      onChange={field.onChange}
                                    />
                                  )}
                                />
                              </div>
                              {personalDetailsErrors.phone_no && (
                                <p className="text-danger pt-1">
                                  {personalDetailsErrors.phone_no.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-btn modal-btn-sm text-end">
                    <button
                      onClick={closePackagesBooking}
                      disabled={isloading ? true : false}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isloading ? true : false}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {isloading ? "Please wait" : "Confirm"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {bookingConfirmToggle && <div className="modal-backdrop fade show"></div>}
      <section className="section popular-services popular-explore pt-10">
        <div className="container">
          <div className="tab-content">
            <div className="tab-pane active">
              <div className="row">
                {packagesList.length > 0 ? (
                  <>
                    {packagesList.length > 0 &&
                      packagesList
                        .slice(0, 60)
                        .map((item: any, index: number) => (
                          <>
                            <div
                              className="col-lg-4 col-md-6 col-12 text-center"
                              data-aos="fade-down"
                              key={index}
                            >
                              <div className="listing-item">
                                <div className="listing-img">
                                  <Link
                                    to={`${routes.listingdetails}/${item?.id}`}
                                  >
                                    <ImageWithBasePath
                                      src={item?.image}
                                      className="img-fluid"
                                      alt="Toyota"
                                    />
                                  </Link>
                                  <div
                                    className="fav-item"
                                  >
                                    <span className="featured-text">
                                      {item?.vehicle_detail.vehicle_type?.name}
                                    </span>
                                    <Link
                                      to={`${routes.listingdetails}/${item?.id}`}
                                      className={`fav-icon ${
                                       ""
                                      }`}
                                    >
                                      <i className="feather icon-heart" />
                                    </Link>
                                  </div>
                                </div>
                                <div className="listing-content">
                                  <div className="listing-features">
                                    <Link
                                      to={`${routes.listingdetails}/${item?.id}`}
                                      className="author-img"
                                    >
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.vehicle_type
                                            ?.icon
                                        }
                                        alt="author"
                                      />
                                    </Link>
                                    <h3 className="listing-title">
                                      <Link to={routes.listingdetails}>
                                        {item?.name}
                                      </Link>
                                    </h3>
                                    <div className="list-rating">
                                      <span className="featured-text">
                                        {item?.vehicle_detail?.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="listing-details-group">
                                    <ul>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.vehicle_type
                                                ?.connectivity_icon
                                            }
                                            alt="Auto"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail?.vehicle_type?.connectivity
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail
                                                .luggage_capacity_icon
                                            }
                                            alt="10 KM"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail
                                              ?.luggage_capacity
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.disabled_icon
                                            }
                                            alt="diabled"
                                          />
                                        </span>
                                        <p>{item?.vehicle_detail?.disabled===1?"Yes":"NO"}</p>
                                      </li>
                                    </ul>
                                    <ul>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.vehicle_type
                                                ?.comfort_icon
                                            }
                                            alt="confort"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail?.vehicle_type?.comfort
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.vehicle_type
                                                ?.safety_icon
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail?.vehicle_type?.safety
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail
                                                .passenger_capacity_icon
                                            }
                                            alt="Persons"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail
                                              ?.passenger_capacity
                                          }
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="listing-location-details justify-content-center">
                                    <div className="listing-price text-center">
                                      <h6>{item?.price} SAR</h6>
                                    </div>
                                  </div>
                                  <div className="listing-button">
                                    <button
                                      onClick={() =>
                                        handleBookingPackages(item)
                                      }
                                      className="btn btn-order"
                                    >
                                      {isloading ? "Please wait" : "Book Now"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                  </>
                ) : (
                  <>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <ProductCardLoader />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <ProductCardLoader />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <ProductCardLoader />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12" data-aos="fade-down">
              <div className="about-content">
                <p>
                  Welcome to Al-Haramain, your trusted companion on the sacred journey through the heart of Saudi Arabia.
                  Founded with the vision of facilitating a seamless, comfortable, and spiritually enriching travel experience,
                  Al-Haramain Cab Service specializes in providing top-tier transportation solutions for pilgrims and
                  visitors to the holy cities of Mecca and Medina.
                </p>
                <p>
                  At Al-Haramain, we believe that a pilgrimage is more than just a journey;
                  it&apos;s an experience that should be cherished and remembered. Our mission is to ensure that every traveler
                  who chooses us finds not just a ride, but a passage that is safe, comfortable,
                  and imbued with the respect and care that such a significant journey deserves.
                </p>

                <div className="row">
                  <p>
                    Embark on your pilgrimage with Al-Haramain Cab Service and discover a travel experience defined
                    by care, dignity, and devotion. Let us take care of the journey, so you can focus on the moments that truly matter.
                    <br />
                    For bookings and more information, visit our website or contact us directly. Your journey to the heart of faith begins here.
                 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HotelTransport;
