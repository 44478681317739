export const setMeta = (title, description, canonicalUrl) => {
  if (title) {
    document.title = title;
  }
  
  if (description) {
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      metaDescription.content = description;
      document.head.appendChild(metaDescription);
    }
  }
  
  if (canonicalUrl) {
    let linkCanonical = document.querySelector('link[rel="canonical"]');
    if (linkCanonical) {
      linkCanonical.setAttribute('href', canonicalUrl);
    } else {
      linkCanonical = document.createElement('link');
      linkCanonical.rel = "canonical";
      linkCanonical.href = canonicalUrl;
      document.head.appendChild(linkCanonical);
    }
  }
};
