import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { all_routes } from "../router/all_routes";
import PhoneNumberField from "./phoneNumberField";
import { API_BASE_URL, CLIENT_BASE_PATH } from "../../environment";
import axios from "axios";
import ProductCardLoader from "../common/loaders/product-card-loader";
import CardIconLoader from "../common/loaders/card-icon-loader";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { setMeta } from "../../core/data/utils/meta";
const Home = () => {
  const routes = all_routes;
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const [popularCartTypes, setPopularCartTypes] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [confirmBookingData, setConfirmBookingData] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(-1);
  const [isloading, setIsLoading] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [bookingConfirmToggle, setBookingConfirmToggle] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState<string>("");
  const [selectedPickupLocation, setSelectedPickupLocation] = useState<string>("");
  const [selectedDropLocation, setSelectedDropLocation] = useState<string>("");
  const [packagePriceData, setPackagePriceData] = useState<any>([]);

  const { id } = useParams();
  useEffect(() => {
    const canonicalUrl = `${CLIENT_BASE_PATH}${id ? `/${id}` : ''}`;
    setMeta(
      "Hajj & Umrah Taxi Services Saudi Arabia | Alharamain Cab",
      "Alharamain Cab is a reliable Hajj & Umrah Taxi Services Saudi Arabia. We are a Premier choice for Hajj & Umrah pilgrims. Book Ride Now!",
      canonicalUrl
    );
  }, []);

  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  const handlePackagesFilter = (index: any, vehicle_type: any) => {
    setActiveIndex(index);
    fetchPackagesList(vehicle_type);
  };

  useEffect(()=>{
    fetchPackagesPrice()
  },[selectedVehicle,selectedPickupLocation,selectedDropLocation])

  const fetchPackagesPrice = async () => {
    try {
      const payload ={
        pickup_location: selectedPickupLocation?selectedPickupLocation:'',
        drop_location: selectedDropLocation?selectedDropLocation:'',
        vehicle_detail: selectedVehicle?selectedVehicle:''
    
      }
      const response = await axios.post(`${API_BASE_URL}/packages/list`, {
         payload,
      });
      setPackagePriceData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (activeIndex === null) {
      handlePackagesFilter(0, "");
    }
  }, [activeIndex]);
  const carTypesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };


 
  const routesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const fetchCarsTypeList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/vehicles_type/list`);
      setPopularCartTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchVehicleTypeList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/vehicles/list`);
      setVehicleTypeList(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchPackagesList = async (make: string) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/packages/list`, {
        vehicle_type: make,
      });
      setPackagesList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchLocationsList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/locations/list`);
      setLocationsList(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchVehicleTypeList();
    fetchCarsTypeList();
    fetchPackagesList("");
    fetchLocationsList();
  }, []);

  const validationSchema = Yup.object().shape({
    vehicle_id: Yup.string().required("Select any Vehicle"),
    pickup_location_id: Yup.string().required("Select any Pickup Location"),
    drop_location_id: Yup.string().required("Select any Drop location"),
    pickup_date_time: Yup.string().required("Select Pickup Date Time"),
    phone_no: Yup.string().required("Please Enter Phone"),
    name: Yup.string(),
    email: Yup.string().required("Please Enter Email"),
  });

  const defaultValues = {
    vehicle_id: "",
    pickup_location_id: "",
    drop_location_id: "",
    pickup_date_time: "",
    phone_no: "",
    name: "",
    email: "",
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset:formReset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = async (data: any) => {
    setIsBookingModalOpen(true);
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const payload = {
        vehicle_id: data.vehicle_id,
        pickup_location_id: data.pickup_location_id,
        drop_off_location_id: data.drop_location_id,
        pickup_date_time: data.pickup_date_time,
        phone_no: data.phone_no,
        name: data.name ? data.name : "",
        baby_seat: false,
        email: data.email ? data.email : "",
        user_id: "",
      };
      const response = await axios.post(`${API_BASE_URL}/booking/add`, payload);

      if (response.status == 201 || response.status == 200) {
        setErrorMessage("");
        setSuccessMessage(response.data.message);

        setIsLoading(false);
      } else {
        setErrorMessage(response.data.message);
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage("");
        setIsLoading(false);
        console.log(successMessage);
        console.log(errorMessage);
      } else {
        setErrorMessage("An error occurred. Please try again later.");
        setIsLoading(false);
      }
    }
    finally {
      formReset();
    }
  };

  const handleBookingPackages = (bookingData: any) => {
    setConfirmBookingData(bookingData);
    setBookingConfirmToggle(true);
  };
  const closeBookingModal = () => {
    setIsBookingModalOpen(false);
  };
  const closePackagesBooking = () => {
    setBookingConfirmToggle(false);
    setConfirmBookingData([]);
  };

  const schemaWithPersonalDetails = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    pickup_date_time1: Yup.string().required("Select Pickup Date Time"),
    phone_no: Yup.string().required("Phone number is required"),
  });

  const {
    handleSubmit: handlePackageSubmit,
    control: handleController,
    formState: { errors: personalDetailsErrors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaWithPersonalDetails),
  });

  const onSubmitPersonalDetails = async (bookingFormData: any) => {
    setIsLoading(true);
    try {
      const payload = {
        package_id: confirmBookingData.id,
        pickup_date_time: bookingFormData.pickup_date_time1,
        phone_no: bookingFormData.phone_no,
        name: bookingFormData.name,
        email: bookingFormData.email,
        user_id: "",
        baby_seat: confirmBookingData.vehicle_detail.baby_seat,
      };
      const response = await axios.post(
        `${API_BASE_URL}/booking/add`,
        payload
      );
      if (response.status == 201 || response.status == 200) {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage("");
        setSuccessMessage(response.data.message);
        setIsLoading(false);
      } else {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage(response.data.message);
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setSuccessMessage("");
        setIsLoading(false);
      } else {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage("An error occurred. Please try again later.");
        setIsLoading(false);
      }
    } finally {
      setConfirmBookingData([]);
      reset();
    }
  };

  return (
    <>
      {/* Booking Status Modal */}
      {isBookingModalOpen && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal fade d-block show`}
          id="delete_modal"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="delete-action">
                  <div className="delete-header">
                    <h4>Booking Status</h4>
                  </div>
                  {isloading && (
                    <p className="py-4">
                      Please wait your booking is in progress ...
                    </p>
                  )}
                  {successMessage && (
                    <p className="py-4 text-success">{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p className="py-4 text-danger">{errorMessage}</p>
                  )}

                  <div className="modal-btn">
                    <div className="row">
                      <div className="col-6 m-auto">
                        <button
                          onClick={closeBookingModal}
                          className="btn btn-primary w-100"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isBookingModalOpen && <div className="modal-backdrop fade show"></div>}
      {/* Packages Booking Modal */}
      {bookingConfirmToggle && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal multi-step fade d-block show`}
          id="complete_booking"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form onSubmit={handlePackageSubmit(onSubmitPersonalDetails)}>
              <div className="modal-content">
                <div className="modal-header" onClick={closePackagesBooking}>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="booking-header">
                    <div className="booking-img-wrap">
                      <div className="book-img">
                        <img
                          src="https://dev.alharamaincab.com/assets/img/cars/carImage.png"
                          alt="img"
                        />
                      </div>
                      <div className="book-info">
                        <h6>{confirmBookingData?.vehicle_detail?.name}</h6>
                        <p>
                          <i className="feather-map-pin" /> From{" "}
                          {confirmBookingData?.pickup_location?.name} {" to "}{" "}
                          {confirmBookingData?.drop_location?.name}
                        </p>
                      </div>
                    </div>
                    <div className="book-amount">
                      <p>Total Amount</p>
                      <h6>{confirmBookingData?.vehicle_detail.price}</h6>
                    </div>
                  </div>
                  <div className="booking-group">
                    <div className="booking-wrapper">
                      <div className="booking-title">
                        <h6>Booking Details</h6>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Booking Type</h6>
                            <p>Package Booking</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Pickup Location</h6>
                            <p>{confirmBookingData?.pickup_location?.name}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>DropOff Location</h6>
                            <p>{confirmBookingData?.pickup_location?.name}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Total Rides</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.total_rides}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Baby Seat</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.baby_seat ===
                              true
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Passenger Capacity</h6>
                            <p>
                              {
                                confirmBookingData?.vehicle_detail
                                  ?.passenger_capacity
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Luggage Capacity</h6>
                            <p>
                              {
                                confirmBookingData?.vehicle_detail
                                  ?.luggage_capacity
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Fuel Type</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.fuel_type}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Pickup Date and Time</h6>
                            <p>{confirmBookingData?.pickup_date_time}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="booking-wrapper">
                      <div className="booking-title">
                        <h6>Personal Details</h6>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="input-block">
                            <label>Name</label>
                            <div className="group-img">
                              <Controller
                                name="name"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.name && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.name.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="input-block">
                            <label>Email</label>
                            <div className="group-img">
                              <Controller
                                name="email"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.email && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="column-group-main">
                            <div className="input-block">
                              <label>Pickup Date Time</label>
                            </div>
                            <div className="input-block-wrapp">
                              <div className="input-block date-widget">
                                <div className="group-img">
                                  <Controller
                                    name="pickup_date_time1"
                                    control={handleController}
                                    render={({ field }) => (
                                      <DatePicker
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        onChange={(date) =>
                                          field.onChange(date)
                                        }
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        placeholderText="Select Date and Time"
                                        className="form-control"
                                        timeInputLabel="Time:"
                                      />
                                    )}
                                  />
                                  {personalDetailsErrors.pickup_date_time1 && (
                                    <p className="text-danger pt-1">
                                      {
                                        personalDetailsErrors.pickup_date_time1
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="search-box-banner p-0 m-0">
                            <div className="input-block column-group-main max-width-100">
                              <label>Phone Number</label>
                              <div className="phone-input-style PhoneInput">
                                <Controller
                                  name="phone_no"
                                  control={handleController}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <PhoneNumberField
                                      onChange={field.onChange}
                                    />
                                  )}
                                />
                              </div>
                              {personalDetailsErrors.phone_no && (
                                <p className="text-danger pt-1">
                                  {personalDetailsErrors.phone_no.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-btn modal-btn-sm text-end">
                    <button
                      onClick={closePackagesBooking}
                      disabled={isloading ? true : false}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isloading ? true : false}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {isloading ? "Please wait" : "Confirm"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {bookingConfirmToggle && <div className="modal-backdrop fade show"></div>}

      <section className="banner-section banner-slider">
        <div className="container">
          <div className="home-banner">
            <div className="row align-items-center mb-5 relative hero-section-block">
              <div className="col-lg-4" data-aos="fade-down">
                <h2 className="mb-5">
                  Welcome To <br />
                  <span className="primary-color">Al-Harmain-Cab</span>
                </h2>
              </div>
              <div className="col-lg-4 text-center banner-imgs">
                <div className="banner-imgs banner-center-image">
                  <ImageWithBasePath
                    src="assets/img/gallery/hero-payment.png"
                    className="img-fluid aos"
                    alt="bannerimage"
                  />
                  <div className="centered-text text-primary">
                    <p>SAR</p>
                    <p>{packagePriceData[0]?.price?packagePriceData[0]?.price:'150'}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-right">
                <div className="banner-imgs banner-right-image">
                  <ImageWithBasePath
                    src="assets/img/gallery/heroCars.png"
                    className="img-fluid aos"
                    alt="bannerimage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-search">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container">
              <div className="search-box-banner">
                <ul className="align-items-center">
                  {/* Choose Your Ride */}
                  <li className="column-group-main">
                    <div className="input-block">
                      <label htmlFor="vehicle_id">
                        Choose Your Ride{" "}
                        {errors.vehicle_id && (
                          <span className="p-1 text-danger">
                            {errors.vehicle_id.message}
                          </span>
                        )}
                      </label>

                      <div className="group-img">
                        <Controller
                          name="vehicle_id"
                          control={control}
                          defaultValue=""
                        
                          render={({ field }) => (
                            <select
                              {...field}
                              id="vehicle_id"
                              className="form-control"
                              onChange={(e:any) => {
                                setSelectedVehicle(e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <option value="">Select a ride</option>
                              {vehicleTypeList.map((item: any, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        <span>
                          <i className="fa-solid fa-taxi" />
                        </span>
                      </div>
                    </div>
                  </li>

                  {/* Pickup Location */}
                  <li className="column-group-main">
                    <div className="input-block">
                      <label htmlFor="pickup_location_id">
                        Pickup Location{" "}
                        {errors.pickup_location_id && (
                          <span className="p-1 text-danger">
                            {errors.pickup_location_id.message}
                          </span>
                        )}
                      </label>
                      <div className="group-img">
                        <Controller
                          name="pickup_location_id" // Set the name for the field
                          control={control}
                          defaultValue="" // Set the default value if needed
                          render={({ field }) => (
                            <select
                              {...field}
                              id="pickup_location_id"
                              className="form-control"
                              onChange={(e:any) => {
                                setSelectedPickupLocation(e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <option value="">
                                Select City, Airport, or Hotel
                              </option>
                              {locationsList.map((item: any, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        <span>
                          <i className="feather icon-map-pin" />
                        </span>
                      </div>
                    </div>
                  </li>

                   {/* Destination Location */}
                   <li className="column-group-main">
                    <div className="input-block">
                      <label htmlFor="drop_location_id">
                        Destination Location
                        {errors.drop_location_id && (
                          <span className="p-1 text-danger">
                            {errors.drop_location_id.message}
                          </span>
                        )}
                      </label>
                      <div className="group-img">
                        <Controller
                          name="drop_location_id" 
                          control={control}
                          defaultValue="" 
                          render={({ field }) => (
                            <select
                              {...field}
                              id="drop_location_id"
                              className="form-control"
                              onChange={(e:any) => {
                                setSelectedDropLocation(e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <option value="">
                                Select City, Airport, or Hotel
                              </option>
                              {locationsList.map((item: any, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        <span>
                          <i className="feather icon-map-pin" />
                        </span>
                      </div>
                    </div>
                  </li>

                 

                  {/* Pickup Date */}
                  <li className="column-group-main">
                    <div className="input-block">
                      <label htmlFor="pickup_date_time">
                        Pickup Date{" "}
                        {errors.pickup_date_time && (
                          <span className="p-1 text-danger">
                            {errors.pickup_date_time.message}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="input-block-wrapp">
                      <div className="input-block date-widget">
                        <div className="group-img">
                          <Controller
                            name="pickup_date_time"
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                selected={
                                  field.value ? new Date(field.value) : null
                                }
                                onChange={(date) => field.onChange(date)}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:mm"
                                placeholderText="Select Date and Time"
                                className="form-control"
                                timeInputLabel="Time:"
                              />
                            )}
                          />
                          <span>
                            <i className="feather icon-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>

                  {/* Phone Number */}
                  <li className="column-group-main">
                    <div className="input-block">
                      <label>
                        Phone Number
                        {errors.phone_no && (
                          <span className="p-1 text-danger">
                            {errors.phone_no.message}
                          </span>
                        )}
                      </label>
                      <div className="phone-input-style PhoneInput">
                        <Controller
                          name="phone_no"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <PhoneNumberField onChange={field.onChange} />
                          )}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="column-group-main">
                    <div className="input-block">
                      <label>
                        Email
                        {errors.email && (
                          <span className="p-1 text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </label>
                      <div className="group-img">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                {...field}
                              />
                            </>
                          )}
                        />

                        <span>
                          <i className="fa-regular fa-user" />
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="view-all text-center pt-4">
                <button
                  type="submit"
                  className="py-4 btn btn-view align-items-center hero-book-btn"
                >
                  {isloading ? "Please wait" : "BOOK NOW !"}
                </button>
                {/* <Link
                    to={routes.listinggrid}
                    className="py-4 btn btn-view align-items-center">BOOK NOW !</Link> */}
              </div>
            </div>
          </form>
        </div>
      </section>

      <section className="section popular-car-type">
        <div className="container">
          <div className="section-heading" data-aos="fade-down">
            <h2>Most Popular Rides</h2>
            <p>
              Experience comfortable vehicles on your pilgrimage to Mecca and
              Medina with Al-Haramain
            </p>
          </div>
          {popularCartTypes.length > 0 ? (
            <>
               <div className="row">
            <div className="popular-slider-group">
              <Slider {...carTypesSettings}>
                {popularCartTypes?.map((item: any, index) => (
                  <div className="listing-owl-item" key={index}>
                    <div className="listing-owl-group">
                      <div className="listing-owl-img">
                        <ImageWithBasePath
                          src={item.image}
                          className="img-fluid"
                          alt="Popular Cartypes"
                        />
                      </div>
                      <h6>{item.name}</h6>
                      <p>Starting from SAR 150</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
            </>
          ) : (
            <>
               <div className="row">
            <div className="popular-slider-group">
              <Slider {...carTypesSettings}>
                {[...Array(5)].map((_, i) => (
                  <div className="listing-owl-item" key={i}>
                    <CardIconLoader />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
            </>
          )}
        </div>
      </section>
      {/* Popular Services */}
      <section className="section popular-services popular-explore pt-0">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Explore Most Popular Offers</h2>
            <p>
              To explore the most popular offers for your Al-Haramain cab
              service, which are significant for pilgrims
            </p>
          </div>
          {/* /Heading title */}
          <div className="row justify-content-center">
            <div className="col-lg-12" data-aos="fade-down">
              <div className="listing-tabs-group">
                <ul className="nav listing-buttons gap-3">
                  <li>
                    <div
                      className={`carttypestabs ${activeIndex === -1 ? "active" : ""}`}
                      onClick={() => handlePackagesFilter(-1, "")}
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/gallery/sedan-icon.svg"
                          alt="vehicle type"
                        />
                      </span>
                      All
                    </div>
                  </li>
                  {popularCartTypes?.map((item: any, index) => (
                    <li key={index}>
                      <div
                        className={`carttypestabs ${activeIndex === index ? "active" : ""}`}
                        onClick={() => handlePackagesFilter(index, item.id)}
                      >
                        <span>
                          <ImageWithBasePath
                            src={item.icon}
                            alt="vehicle type"
                          />
                        </span>
                        {item.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane active">
              <div className="row">
                {packagesList.length > 0 ? (
                  <>
                    {packagesList.length > 0 &&
                      packagesList
                        .slice(0, 60)
                        .map((item: any, index: number) => (
                          <>
                            <div
                              className="col-lg-4 col-md-6 col-12 text-center"
                              data-aos="fade-down"
                              key={index}
                            >
                              <div className="listing-item">
                                <div className="listing-img">
                                  <Link
                                    to={`${routes.listingdetails}/${item?.id}`}
                                  >
                                    <ImageWithBasePath
                                      src={item?.image}
                                      className="img-fluid"
                                      alt="Toyota"
                                    />
                                  </Link>
                                  <div
                                    className="fav-item"
                                    key={1}
                                    onClick={() => handleItemClick(1)}
                                  >
                                    <span className="featured-text">
                                      {item?.vehicle_detail.vehicle_type?.name}
                                    </span>
                                    <Link
                                      to={`${routes.listingdetails}/${item?.id}`}
                                      className={`fav-icon ${
                                        selectedItems[1] ? "selected" : ""
                                      }`}
                                    >
                                      <i className="feather icon-heart" />
                                    </Link>
                                  </div>
                                </div>
                                <div className="listing-content">
                                  <div className="listing-features">
                                    <Link
                                      to={`${routes.listingdetails}/${item?.id}`}
                                      className="author-img"
                                    >
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.vehicle_type
                                            ?.icon
                                        }
                                        alt="author"
                                      />
                                    </Link>
                                    <h3 className="listing-title">
                                      <Link to={routes.listingdetails}>
                                        {item?.name}
                                      </Link>
                                    </h3>
                                    <div className="list-rating">
                                      <span className="featured-text">
                                        {item?.vehicle_detail?.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="listing-details-group">
                                    <ul>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.vehicle_type
                                                ?.connectivity_icon
                                            }
                                            alt="Auto"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail?.vehicle_type?.connectivity
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail
                                                .luggage_capacity_icon
                                            }
                                            alt="10 KM"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail
                                              ?.luggage_capacity
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.disabled_icon
                                            }
                                            alt="diabled"
                                          />
                                        </span>
                                        <p>{item?.vehicle_detail?.disabled===1?"Yes":"NO"}</p>
                                      </li>
                                    </ul>
                                    <ul>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.vehicle_type
                                                ?.comfort_icon
                                            }
                                            alt="confort"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail?.vehicle_type?.comfort
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail?.vehicle_type
                                                ?.safety_icon
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail?.vehicle_type?.safety
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <span>
                                          <ImageWithBasePath
                                            src={
                                              item?.vehicle_detail
                                                .passenger_capacity_icon
                                            }
                                            alt="Persons"
                                          />
                                        </span>
                                        <p>
                                          {
                                            item?.vehicle_detail
                                              ?.passenger_capacity
                                          }
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="listing-location-details justify-content-center">
                                    <div className="listing-price text-center">
                                      <h6>{item?.price} SAR</h6>
                                    </div>
                                  </div>
                                  <div className="listing-button">
                                    <button
                                      onClick={() =>
                                        handleBookingPackages(item)
                                      }
                                      className="btn btn-order"
                                    >
                                      {isloading ? "Please wait" : "Book Now"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                  </>
                ) : (
                  <>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <ProductCardLoader />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <ProductCardLoader />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <ProductCardLoader />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Popular Services */}

      {/* Facts By The Numbers */}
      <section className="section facts-number">
        <div className="facts-left">
          <ImageWithBasePath
            src="assets/img/bg/facts-left.png"
            className="img-fluid"
            alt="facts left"
          />
        </div>
        <div className="facts-right">
          <ImageWithBasePath
            src="assets/img/bg/facts-right.png"
            className="img-fluid"
            alt="facts right"
          />
        </div>
        <div className="container">
          <div className="counter-group">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="flex-fill m-auto text-end whatsapp-section">
                  <div className="">
                    <h2 className="title text-white">Book Your car</h2>
                    <h2 className="title text-white">
                      {" "}
                      the{" "}
                      <span className="text-primary text-30">Eeasiest way</span>
                    </h2>
                    <h2 className="title text-white">With Whatsapp</h2>
                    <div className="view-all pt-3">
                      <a
                        href="https://wa.me/966580125300"
                        className="py-4 border-0 btn w-50 btn-view align-items-right"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        BOOK NOW!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className=" flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <ImageWithBasePath
                      src="assets/img/cars/whatsapp-phone.png"
                      alt=""
                      className="m-auto whatsapp-section-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Facts By The Numbers */}

      {/* Popular Cartypes */}
      <section className="section popular-car-type">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Most Popular Routes</h2>
            <p>Discover our Most Popular Routes for your pilgrimage</p>
          </div>
          {/* /Heading title */}

          {locationsList.length > 0 ? (
            <>
              <div className="row">
                <div className="popular-slider-group popular-routes">
                  <Slider {...routesSettings}>
                    {locationsList.map((item: any, index) => (
                      <>
                        <div className="listing-owl-item" key={index}>
                          <div className="listing-owl-group">
                            <div className="listing-owl-img">
                              <ImageWithBasePath
                                src="assets/img/gallery/Route.svg"
                                className="img-fluid"
                                alt="Popular Cartypes"
                              />
                            </div>
                            <h6>{item?.name}</h6>
                            <p>Starting from 150</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </Slider>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="popular-slider-group">
                  <Slider {...routesSettings}>
                    <div className="listing-owl-item">
                      <CardIconLoader />
                    </div>
                    <div className="listing-owl-item">
                      <CardIconLoader />
                    </div>
                    <div className="listing-owl-item">
                      <CardIconLoader />
                    </div>
                    <div className="listing-owl-item">
                      <CardIconLoader />
                    </div>
                    <div className="listing-owl-item">
                      <CardIconLoader />
                    </div>
                  </Slider>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      {/* /Popular Cartypes */}

      {/* services */}
      <section className="section services">
        <div className="service-right">
          <ImageWithBasePath
            src="assets/img/bg/service-right.svg"
            className="img-fluid"
            alt="services right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>How It Works</h2>
            <p>
              Al-HaramainCab simplifies your pilgrimage journey with a focus on
              comfort, safety, and spiritual peace. Here’s how our service works
              in simple steps
            </p>
          </div>
          {/* /Heading title */}
          <div className="services-work">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-secondary">
                    <ImageWithBasePath
                      className="icon-img bg-secondary"
                      src="assets/img/icons/services-icon-01.svg"
                      alt="Book Your Ride"
                    />
                  </div>
                  <div className="services-content">
                    <h3>1. Book Your Ride</h3>
                    <p>
                      Use our website or mobile app to choose your trip details.
                      Pick the date, time, and destination for your pilgrimage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-warning">
                    <ImageWithBasePath
                      className="icon-img bg-warning"
                      src="assets/img/icons/services-icon-02.svg"
                      alt="Receive Confirmation"
                    />
                  </div>
                  <div className="services-content">
                    <h3>2. Receive Confirmation</h3>
                    <p>
                      Youll get a confirmation message with your drivers details
                      and the estimated time of arrival. This message ensures
                      everything is set for your trip.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-dark">
                    <ImageWithBasePath
                      className="icon-img bg-dark"
                      src="assets/img/icons/services-icon-03.svg"
                      alt="Enjoy Your Journey"
                    />
                  </div>
                  <div className="services-content">
                    <h3>3. Enjoy Your Journey</h3>
                    <p>
                      Your driver will arrive at the scheduled time, ready to
                      take you to your destination. The ride is comfortable,
                      safe, and respectful of your pilgrimage experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /services */}

      {/* App Available */}
      <section className="section container">
        <div className="user-app-group">
          <div className="app-left">
            <ImageWithBasePath
              src="assets/img/bg/app-left.png"
              className="img-fluid"
              alt="App Available"
            />
          </div>
          <div className="app-right">
            <ImageWithBasePath
              src="assets/img/bg/app-right.png"
              className="img-fluid"
              alt="App Available"
            />
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="userapp-heading">
                <h2 data-aos="fade-down mb-3">
                  Seamless Pilgrimage Travel Al-Haramain Cab Service
                </h2>
                <p data-aos="fade-down">
                  Experience comfort, safety, and spiritual guidance with
                  Al-Haramain Cab Service. Our professional drivers and top-tier
                  transportation ensure a seamless journey.
                </p>
                <div className="download-btn">
                  <div className="app-avilable" data-aos="fade-down">
                    {/* <Link to="#">
                      <ImageWithBasePath
                        src="assets/img/play-store.svg"
                        alt="PlayStore"
                      />
                    </Link> */}
                  </div>
                  <div className="app-avilable" data-aos="fade-down">
                    {/* <Link to="#">
                      <ImageWithBasePath
                        src="assets/img/apple.svg"
                        alt="AppStore"
                      />
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="car-holder">
                <ImageWithBasePath
                  className="app-car img-fluid"
                  src="assets/img/gallery/app-car.jpg"
                  alt="App Available"
                  data-aos="fade-down"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /App Available */}
      </section>
    </>
  );
};

export default Home;
