export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
// Authtentication actions

export const loginUser = (userData: any) => ({
  type: LOGIN_USER,
  payload: userData,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});
