import React, { useEffect, useState } from "react";
import Breadcrumbs from "../common/Breadcrumbs";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../router/all_routes";
import ListingSidebar from "./listingsidebar";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
// import { listgrids } from "../../core/data/interface/interface";
import Aos from "aos";
import { API_BASE_URL, CLIENT_BASE_PATH } from "../../environment";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneNumberField from "../home/phoneNumberField";
import ProductCardLoader from "../common/loaders/product-card-loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMeta } from "../../core/data/utils/meta";

const ListingGrid = () => {
  const [packagesList, setPackagesList] = useState([]);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [bookingConfirmToggle, setBookingConfirmToggle] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [confirmBookingData, setConfirmBookingData] = useState<any>([]);
  const [popularCartTypes, setPopularCartTypes] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const routes = all_routes;
  const optionsTwo = [
    { value: 1, label: "Low to High" },
    { value: 2, label: "High to Low" },
  ];

  useEffect(() => {
    const canonicalUrl = `${CLIENT_BASE_PATH}/package`;
    setMeta(
      "Hajj & Umrah Taxi Services Saudi Arabia | Alharamain Cab",
      "Alharamain Cab is a reliable Hajj & Umrah Taxi Services Saudi Arabia. We are a Premier choice for Hajj & Umrah pilgrims. Book Ride Now!",
      canonicalUrl
    );
  }, []);

  const fetchVehicleTypeList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/vehicles/list`);
      setVehicleTypeList(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCarsTypeList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/vehicles_type/list`);
      setPopularCartTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchVehicleTypeList();
    fetchCarsTypeList();
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  const fetchPackagesList = async (make: string) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/packages/list`, {
        vehicle_type: make,
      });
      setPackagesList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCarCategorySelect = (name: string) => {
    fetchPackagesList(name);
  };

  useEffect(() => {
    fetchPackagesList("");
  }, []);

  const handleBookingPackages = (bookingData: any) => {
    setConfirmBookingData(bookingData);
    setBookingConfirmToggle(true);
  };
  const closeBookingModal = () => {
    setIsBookingModalOpen(false);
  };
  const closePackagesBooking = () => {
    setBookingConfirmToggle(false);
    setConfirmBookingData([]);
  };

  const schemaWithPersonalDetails = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      pickup_date_time: Yup.string().required("Select Pickup Date Time"),
    phone_no: Yup.string().required("Phone number is required"),
  });

  const {
    handleSubmit: handlePackageSubmit,
    control: handleController,
    formState: { errors: personalDetailsErrors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaWithPersonalDetails),
  });

  const onSubmitPersonalDetails = async (bookingFormData: any) => {
    setIsLoading(true);
    try {
      const payload = {
        package_id: confirmBookingData.id,
        pickup_date_time: bookingFormData.pickup_date_time,
        baby_seat: confirmBookingData.vehicle_detail.baby_seat,
        phone_no: bookingFormData.phone_no,
        name: bookingFormData.name,
        email: bookingFormData.email,
        user_id: "",
      };
      const response = await axios.post(
        `${API_BASE_URL}/packages/booking`,
        payload
      );
      if (response.status != 400) {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage("");
        setSuccessMessage(response.data.message);
        setIsLoading(false);
      } else {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage(response.data.message);
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setSuccessMessage("");
        setIsLoading(false);
      } else {
        setBookingConfirmToggle(false);
        setIsBookingModalOpen(true);
        setErrorMessage("An error occurred. Please try again later.");
        setIsLoading(false);
      }
    } finally {
      setConfirmBookingData([]);
      reset();
    }
  };


  const handleSelectTypeFilter = (selectedOption: any) => {
    const make = selectedOption.value;
    fetchPackagesList(make);
  };

  console.log(packagesList,'list')
  return (
    <>
      {/* Booking Status Modal */}
      {isBookingModalOpen && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal fade d-block show`}
          id="delete_modal"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="delete-action">
                  <div className="delete-header">
                    <h4>Booking Status</h4>
                  </div>
                  {isloading && (
                    <p className="py-4">
                      Please wait your booking is in progress ...
                    </p>
                  )}

                  {successMessage && (
                    <p className="py-4 text-success">{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p className="py-4 text-danger">{errorMessage}</p>
                  )}

                  <div className="modal-btn">
                    <div className="row">
                      <div className="col-6 m-auto">
                        <button
                          onClick={closeBookingModal}
                          className="btn btn-primary w-100"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isBookingModalOpen && <div className="modal-backdrop fade show"></div>}
      {/* Packages Booking Modal */}
      {bookingConfirmToggle && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal multi-step fade d-block show`}
          id="complete_booking"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form onSubmit={handlePackageSubmit(onSubmitPersonalDetails)}>
              <div className="modal-content">
                <div className="modal-header" onClick={closePackagesBooking}>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="booking-header">
                    <div className="booking-img-wrap">
                      <div className="book-img">
                        <img
                          src="https://dev.alharamaincab.com/assets/img/cars/carImage.png"
                          alt="img"
                        />
                      </div>
                      <div className="book-info">
                        <h6>{confirmBookingData?.vehicle_detail?.name}</h6>
                        <p>
                          <i className="feather-map-pin" /> From{" "}
                          {confirmBookingData?.pickup_location?.name} {" to "}{" "}
                          {confirmBookingData?.drop_location?.name}
                        </p>
                      </div>
                    </div>
                    <div className="book-amount">
                      <p>Total Amount</p>
                      <h6>{confirmBookingData?.vehicle_detail.price}</h6>
                    </div>
                  </div>
                  <div className="booking-group">
                    <div className="booking-wrapper">
                      <div className="booking-title">
                        <h6>Booking Details</h6>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Booking Type</h6>
                            <p>Package Booking</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Pickup Location</h6>
                            <p>{confirmBookingData?.pickup_location?.name}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>DropOff Location</h6>
                            <p>{confirmBookingData?.pickup_location?.name}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Total Rides</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.total_rides}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Baby Seat</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.baby_seat ===
                              true
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Passenger Capacity</h6>
                            <p>
                              {
                                confirmBookingData?.vehicle_detail
                                  ?.passenger_capacity
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Luggage Capacity</h6>
                            <p>
                              {
                                confirmBookingData?.vehicle_detail
                                  ?.luggage_capacity
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Fuel Type</h6>
                            <p>
                              {confirmBookingData?.vehicle_detail?.fuel_type}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="booking-view">
                            <h6>Pickup Date and Time</h6>
                            <p>{confirmBookingData?.pickup_date_time}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="booking-wrapper">
                      <div className="booking-title">
                        <h6>Personal Details</h6>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="input-block">
                            <label>Name</label>
                            <div className="group-img">
                              <Controller
                                name="name"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.name && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.name.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="input-block">
                            <label>Email</label>
                            <div className="group-img">
                              <Controller
                                name="email"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.email && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.email.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
  <div className="input-block">
    <label>Pickup Date Time</label>
    <div className="group-img">
      <Controller
        name="pickup_date_time"
        control={handleController}
        render={({ field }) => (
          <DatePicker
          selected={
            field.value ? new Date(field.value) : null
          }
            onChange={(date) => field.onChange(date)}
            showTimeSelect
            dateFormat="yyyy-MM-dd HH:mm"
            placeholderText="Select Date and Time"
            className="form-control"
            timeInputLabel="Time:"
          />
        )}
      />
      {personalDetailsErrors.pickup_date_time && (
        <p className="text-danger pt-1">
          {personalDetailsErrors.pickup_date_time.message}
        </p>
      )}
    </div>
  </div>
</div>



                        <div className="col-12">
                          <div className="search-box-banner p-0 m-0">
                            <div className="input-block column-group-main max-width-100">
                              <label>Phone Number</label>
                              <div className="phone-input-style PhoneInput">
                                <Controller
                                  name="phone_no"
                                  control={handleController}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <PhoneNumberField
                                      onChange={field.onChange}
                                    />
                                  )}
                                />
                              </div>
                              {personalDetailsErrors.phone_no && (
                                <p className="text-danger pt-1">
                                  {personalDetailsErrors.phone_no.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-btn modal-btn-sm text-end">
                    <button
                      onClick={closePackagesBooking}
                      disabled={isloading ? true : false}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isloading ? true : false}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {isloading ? "Please wait" : "Confirm"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {bookingConfirmToggle && <div className="modal-backdrop fade show"></div>}

      <div className="main-wrapper">
        <Breadcrumbs title="All Packages" subtitle="list" />
        <div className="sortby-sec">
          <div className="container">
            <div className="sorting-div">
              <div className="row d-flex align-items-center">
                <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                  <div className="count-search">
                    <p>Showing {packagesList.length} Results</p>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
                  <div className="product-filter-group">
                    <div className="sortbyset">
                      <div className="sorting-select select-two">
                        <Select
                          className="select w-100"
                          options={optionsTwo}
                          placeholder="Low to High"
                          isSearchable={false}
                        />
                      </div>
                      <div className="sorting-select select-three">
                        <Select
                          className="select"
                          options={popularCartTypes.map((item: any) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          onChange={handleSelectTypeFilter} 
                          placeholder="Select Filter"
                          aria-labelledby="carDropdownLabel"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section car-listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-12 theiaStickySidebar">
                <div className="stickybar">
                  <ListingSidebar
                    popularCartTypes={popularCartTypes}
                    vehicleTypeList={vehicleTypeList}
                    onSelectCarCategory={handleCarCategorySelect}
                  />
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  {packagesList?.length > 0 ? (
                    <>
                      {packagesList.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="col-xl-6 col-lg-6 col-md-6 col-12"
                        >
                          <div className="listing-item">
                            <div className="listing-img">
                              <Link to={`${routes?.listingdetails}/${item?.id}`}>
                                <ImageWithBasePath
                                  src={item?.image}
                                  className="img-fluid"
                                  alt="Toyota"
                                />
                              </Link>
                              <div className="fav-item">
                                <span className="featured-text">
                                  {item?.vehicle_detail?.vehicle_type?.name}
                                </span>
                                <Link
                                  to={`${routes?.listingdetails}/${item?.id}`}
                                >
                                  <i className="feather icon-heart" />
                                </Link>
                              </div>
                            </div>
                            <div className="listing-content">
                              <div className="listing-features">
                                <Link
                                  to={`${routes?.listingdetails}/${item?.id}`}
                                  className="author-img"
                                >
                                  <ImageWithBasePath
                                    src={
                                      item?.vehicle_detail?.vehicle_type?.icon
                                    }
                                    alt="author"
                                  />
                                </Link>
                                <h3 className="listing-title">
                                  <Link to={routes?.listingdetails}>
                                    {item?.vehicle_detail?.name}
                                  </Link>
                                </h3>
                                <div className="list-rating">
                                  <span className="featured-text">
                                    {item?.vehicle_detail?.total_rides}+ Rides
                                  </span>
                                  <span className="featured-text">
                                    Ratings ({item?.vehicle_detail?.rating})
                                  </span>
                                </div>
                              </div>
                              <div className="listing-details-group">
                                <ul>
                                  <li>
                                    <span>
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.vehicle_type
                                            ?.connectivity_icon
                                        }
                                        alt="Auto"
                                      />
                                    </span>
                                    <p>
                                      {
                                        item.vehicle_detail?.vehicle_type?.connectivity
                                      }
                                    </p>
                                  </li>
                                  <li>
                                    <span>
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.luggage_capacity_icon
                                        }
                                        alt="10 KM"
                                      />
                                    </span>
                                    <p>
                                      {item?.vehicle_detail?.luggage_capacity}
                                    </p>
                                  </li>
                                  <li>
                                    <span>
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.fuel_type_icon
                                        }
                                        alt="Petrol"
                                      />
                                    </span>
                                    <p>{item?.vehicle_detail?.fuel_type}</p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <span>
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.vehicle_type
                                            ?.comfort_icon
                                        }
                                        alt="confort"
                                      />
                                    </span>
                                    <p>
                                      {item?.vehicle_detail?.vehicle_type?.comfort}
                                    </p>
                                  </li>
                                  <li>
                                    <span>
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.vehicle_type
                                            ?.safety_icon
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <p>
                                      {item?.vehicle_detail?.vehicle_type?.safety}
                                    </p>
                                  </li>
                                  <li>
                                    <span>
                                      <ImageWithBasePath
                                        src={
                                          item?.vehicle_detail?.passenger_capacity_icon
                                        }
                                        alt="Persons"
                                      />
                                    </span>
                                    <p>
                                      {item?.vehicle_detail?.passenger_capacity}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                              <div className="listing-location-details justify-content-center">
                                <div className="listing-price text-center">
                                  <h6>{item?.price} SAR</h6>
                                </div>
                              </div>
                              <div className="listing-button">
                                <button
                                  onClick={() => handleBookingPackages(item)}
                                  className="btn btn-order"
                                >
                                  {isloading ? "Please wait" : "Book Now"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="listing-item">
                          <ProductCardLoader />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="listing-item">
                          <ProductCardLoader />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="listing-item">
                          <ProductCardLoader />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="listing-item">
                          <ProductCardLoader />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="listing-item">
                          <ProductCardLoader />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="listing-item">
                          <ProductCardLoader />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ListingGrid;
