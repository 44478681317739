'use client'
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CornerDownLeft } from 'react-feather';
import { all_routes } from "../router/all_routes";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { API_BASE_URL } from "../../environment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../core/data/redux/action";

const Login = () => {
  const route = all_routes
  const token = useSelector((state: any) => state.auth.token);
  const navigate = useNavigate()
  useEffect(()=>{
    if(token.length > 0) {
      navigate(route.home);
    }
  },[])
  const [isToggle, setIsToggle] = useState(false);
  const [successMessage,setSuccessMessage] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [isloading,setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
});

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data:any) => {
    setIsLoading(true)
    setSuccessMessage('')
    setErrorMessage('')
    try{
      const response = await axios.post(`${API_BASE_URL}/auth/login`,data)
  
      if(response.status != 400){
        setErrorMessage('')
        window.scrollTo(0, 0);
        setSuccessMessage(response.data.message)
        dispatch(loginUser(response?.data));
        navigate(route.home);
        setIsLoading(false)
      } else{
        setErrorMessage(response.data.message)
        setSuccessMessage('')
        window.scrollTo(0, 0);
        setIsLoading(false)
      }
    }
    catch(error:any){
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage('')
        window.scrollTo(0, 0);
        setIsLoading(false)
      } else {
        setErrorMessage("An error occurred. Please try again later.");
        window.scrollTo(0, 0);
        setIsLoading(false)
        dispatch(loginUser(data));
      }
    }
  };

  return (
    <div className="main-wrapper login-body">
  
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="sign-group">
                <Link to={route.home} className="btn sign-up">
                  <span>
                  <CornerDownLeft/>
                  </span>{" "}
                  Back To Home
                </Link>
              </div>
              <div className="text-center">
                  {successMessage && (
                    <span className="text-success">{successMessage}</span>
                  )}
                   {errorMessage && (
                    <span className="text-danger">{errorMessage}</span>
                  )}
                </div>
              <h1>Sign In</h1>
              <p className="account-subtitle">
                We will send a confirmation code to your email.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          {...field}
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email.message}</div>
                        )}
                        </>
                      )}
                    />
                </div>
                <div className="input-block">
                  <label className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div
                      className="pass-group"
                      onClick={() => setIsToggle(!isToggle)}
                    >
                       <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                        type={isToggle ? "text" : "password"}
                        className="form-control pass-input"
                          placeholder="Enter Password"
                          {...field}
                        />
                        {errors.password && (
                          <div className="text-danger">{errors.password.message}</div>
                        )}
                        </>
                      )}
                    />
                      <span
                        className={`fas toggle-password ${
                          isToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                </div>
                <div className="input-block">
                  <Link className="forgot-link" to={route.forgotpassword}>
                    Forgot Password ?
                  </Link>
                </div>
                <div className="input-block m-0">
                  <label className="custom_check d-inline-flex">
                    <span>Remember me</span>
                    <input type="checkbox" name="remeber" />
                    <span className="checkmark" />
                  </label>
                </div>
                <button type="submit" className="btn btn-outline-light w-100 btn-size mt-1">
                   {isloading?'Please wait':'Sign In'} 
                  </button>
                
                <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or-log">
                    Dont have an account?
                  </span>
                </div>
                {/* Social Login */}
                {/* <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="img-fluid"
                        alt="Google"
                      />
                    </span>
                    Log in with Google
                  </Link>
                </div>
                <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/facebook.svg"
                        className="img-fluid"
                        alt="Facebook"
                      />
                    </span>
                    Log in with Facebook
                  </Link>
                </div> */}
                {/* /Social Login */}
                <div className="text-center dont-have">
                   <Link to={route.signup}>Register</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer className="log-footer">
        <div className="container-fluid">
          {/* Copyright */}
          <div className="copyright">
            <div className="copyright-text">
              <p>© 2024 Al-haramain Cab All Rights Reserved.</p>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </footer>
      {/* /Footer */}
    </div>
  );
};

export default Login;
