import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

// Extend the props interface to include style and className
interface PhoneNumberFieldProps {
  onChange?: (value: string) => void;
  style?: React.CSSProperties; // Adding style prop
  className?: string; // Adding className prop
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({ onChange, style, className }) => {
  const [value, setValue] = useState<string | undefined>();

  const handleChange = (phone: string | undefined) => {
    setValue(phone);
    if (onChange && phone && isPossiblePhoneNumber(phone)) {
      onChange(phone);
    }
  };

  return (
    <PhoneInput
      international
      defaultCountry="US"
      value={value}
      onChange={handleChange}
      style={style}
      className={className}
    />
  );
};

export default PhoneNumberField;
