import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../common/Breadcrumbs";
import { setMeta } from "../../core/data/utils/meta";
import { CLIENT_BASE_PATH } from "../../environment";

const Faq = () => {

  useEffect(() => {
    const canonicalUrl = `${CLIENT_BASE_PATH}/faq`;
    setMeta(
      "Hajj & Umrah Taxi Services Saudi Arabia | Alharamain Cab",
      "Alharamain Cab is a reliable Hajj & Umrah Taxi Services Saudi Arabia. We are a Premier choice for Hajj & Umrah pilgrims. Book Ride Now!",
      canonicalUrl
    );
  }, []);


  return (
    <>
      {/* Breadscrumb */}
      <Breadcrumbs title="Faq" subtitle="Pages" />
      {/* /Breadscrumb */}
      <section className="section faq-section">
        <div className="container">
          <div className="faq-info">
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="false"
                >
                  How do I book my Umrah journey with you?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse">
                <p>
                  You can book your journey through our website or mobile app. Simply select your desired package, fill in your details, and choose your payment option. A confirmation will be sent to you immediately after booking.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  What types of packages do you offer for the Umrah journey?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                  We offer a variety of packages tailored to meet different needs and budgets, including basic, premium, and luxury options. Each package includes travel, accommodation, and visa processing, with detailed information available on our website.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqThree"
                  aria-expanded="false"
                >
                  Can you accommodate special requests or needs?
                </Link>
              </h4>
              <div id="faqThree" className="card-collapse collapse">
                <p>
                  Yes, we strive to accommodate all special requests, including dietary restrictions, wheelchair accessibility, and any other personal needs. Please mention your requirements when booking, and we&apos;ll do our best to meet them.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqFour"
                  aria-expanded="false"
                >
                  What is included in the cost of an Umrah package?
                </Link>
              </h4>
              <div id="faqFour" className="card-collapse collapse">
                <p>
                  Generally, our packages include visa processing, flights, hotel accommodation, transportation in Saudi Arabia, and meals. Specific details vary by package, so please refer to the package description or contact us for more information.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqFive"
                  aria-expanded="false"
                >
                  How early should I book my Umrah journey?
                </Link>
              </h4>
              <div id="faqFive" className="card-collapse collapse">
                <p>
                  We recommend booking at least two weeks in advance, especially during peak seasons, to ensure the best prices and availability. However, we can accommodate last-minute bookings based on availability.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSix"
                  aria-expanded="false"
                >
                  Is there a guide or assistance available during the Umrah journey?
                </Link>
              </h4>
              <div id="faqSix" className="card-collapse collapse">
                <p>
                  Yes, our packages include the services of experienced guides who are knowledgeable about the Umrah rites and can provide assistance throughout your journey.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSeven"
                  aria-expanded="false"
                >
                  What is your cancellation policy?
                </Link>
              </h4>
              <div id="faqSeven" className="card-collapse collapse">
                <p>
                  Our cancellation policy varies by package. Typically, cancellations made within a certain period before departure may be eligible for a full or partial refund. Please review the terms and conditions of your package for detailed information.
                </p>
              </div>
            </div>

            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqEight"
                  aria-expanded="false"
                >
                  How do I prepare for Umrah?
                </Link>
              </h4>
              <div id="faqEight" className="card-collapse collapse">
                <p>
                  We provide all travelers with a comprehensive guide on how to prepare for Umrah, including necessary documents, recommended clothing, and spiritual preparation. Our team is also available to answer any questions you may have.
                </p>
              </div>
            </div>

            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqNine"
                  aria-expanded="false"
                >
                  Are flights and accommodations flexible?
                </Link>
              </h4>
              <div id="faqNine" className="card-collapse collapse">
                <p>
                  Yes, we offer a range of flight and accommodation options to suit different preferences and budgets. You can choose your airline and hotel when booking your package.
                </p>
              </div>
            </div>

            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTen"
                  aria-expanded="false"
                >
                  How can I contact you for more questions?
                </Link>
              </h4>
              <div id="faqTen" className="card-collapse collapse">
                <p>
                  You can contact us through our website&apos;s contact form, email, or phone. Our customer service team is available 24/7 to assist you with any inquiries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
