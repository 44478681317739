import React, { useEffect } from "react";
import CountUp from "react-countup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Breadcrumbs from "../common/Breadcrumbs";
import Link from "antd/es/typography/Link";
import { setMeta } from "../../core/data/utils/meta";
import { CLIENT_BASE_PATH } from "../../environment";

const About = () => {

  useEffect(() => {
    const canonicalUrl = `${CLIENT_BASE_PATH}/about`;
    setMeta(
      "Hajj & Umrah Taxi Services Saudi Arabia | Alharamain Cab",
      "Alharamain Cab is a reliable Hajj & Umrah Taxi Services Saudi Arabia. We are a Premier choice for Hajj & Umrah pilgrims. Book Ride Now!",
      canonicalUrl
    );
  }, []);

  const testimonials = [
    {
      id: 1,
      name: "Aisha Khan",
      image: "/assets/img/profiles/avatar-02.jpg",
      rating: 5.0,
      review:
        "Embarking on my Umrah journey with this service was a blessing. The comfort and respect shown to us throughout were beyond my expectations. Truly, they made our spiritual journey a memory to cherish.",
    },
    {
      id: 2,
      name: "Mohammed Al-Farsi",
      image: "/assets/img/profiles/avatar-03.jpg",
      rating: 5.0,
      review:
        "From the moment we started our Umrah journey until we returned home, the dedication and care from the team were exceptional. Their understanding of our needs made everything seamless. Highly recommend their services.",
    },
    {
      id: 3,
      name: "Bilal Ahmad",
      image: "/assets/img/profiles/avatar-04.jpg",
      rating: 5.0,
      review:
        "The spiritual journey of Umrah is close to my heart, and choosing them as our travel partner made it even more special. Their attention to every little detail ensured our focus remained on our prayers and rituals. JazakAllah khair for the unforgettable experience.",
    },
    {
      id: 4,
      name: "Fatima Zubair",
      image: "/assets/img/profiles/avatar-06.jpg",
      rating: 5.0,
      review:
        "Our Umrah trip was not just a journey but a profoundly moving experience, thanks to their exemplary service. The spiritual ambiance was maintained throughout, making it a peaceful pilgrimage. I&apos;m deeply grateful for their kindness and professionalism.",
    },
    {
      id: 5,
      name: "Ibrahim Yusuf",
      image: "/assets/img/profiles/avatar-07.jpg",
      rating: 5.0,
      review:
        "As someone who has gone on Umrah multiple times, I can confidently say their service stands out. The respect for cultural and religious practices is evident, and their organization made our pilgrimage smooth and worry-free.",
    },
    {
      id: 6,
      name: "Noor Al-Huda",
      image: "/assets/img/profiles/avatar-07.jpg",
      rating: 5.0,
      review:
        "Their team made our Umrah journey a spiritually enriching experience. The respect, cleanliness, and punctuality they maintained throughout our trip were commendable. It was an honor to travel with them.",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 375, 
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      {/* Breadscrumb Section */}
      <Breadcrumbs title="About" subtitle="Pages" />
      {/* /Breadscrumb Section */}
      {/* About */}
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6" data-aos="fade-down">
              <div className="about-img">
                <div className="about-exp">
                  <span>Best Cab Service</span>
                </div>
                <div className="abt-img">
                
                  <ImageWithBasePath
                    src="/assets/img/bg/aboutus.jpg"
                    className="img-fluid"
                    alt="About us"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-down">
              <div className="about-content">
                <h6>About Al-Haramain Cab Service</h6>
                <p>
                  Welcome to Al-Haramain, your trusted companion on the sacred journey through the heart of Saudi Arabia.
                  Founded with the vision of facilitating a seamless, comfortable, and spiritually enriching travel experience,
                  Al-Haramain Cab Service specializes in providing top-tier transportation solutions for pilgrims and
                  visitors to the holy cities of Mecca and Medina.
                </p>
                <b>Our Mission</b>
                <p>
                  At Al-Haramain, we believe that a pilgrimage is more than just a journey;
                  it&apos;s an experience that should be cherished and remembered. Our mission is to ensure that every traveler
                  who chooses us finds not just a ride, but a passage that is safe, comfortable,
                  and imbued with the respect and care that such a significant journey deserves.
                </p>

                <div className="row">
                  <p><b>Why Choose Al-Haramain?</b></p>
                  <div className="col-md-6">
                    <ul>
                      <li>Dedicated Pilgrimage Support</li>
                      <li>Cultural Insight</li>
                      <li>Unmatched Comfort</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>Respectful and Professional Drivers</li>
                      <li>Commitment to Excellence</li>
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <p><b>Join Us</b></p>
                  <p>
                    Embark on your pilgrimage with Al-Haramain Cab Service and discover a travel experience defined
                    by care, dignity, and devotion. Let us take care of the journey, so you can focus on the moments that truly matter.
                    <br />
                    For bookings and more information, visit our website or contact us directly. Your journey to the heart of faith begins here.
                 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /About */}
      {/* services */}
      <section className="section services bg-light-primary">
        <div className="service-right">
          <ImageWithBasePath
            src="/assets/img/bg/service-right.svg"
            className="img-fluid"
            alt="services right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>How It Works</h2>
            <p>
              Al-HaramainCab simplifies your pilgrimage journey with a focus on comfort, safety, and spiritual peace.
              Here’s how our service works in simple steps
            </p>
          </div>
          {/* /Heading title */}
          <div className="services-work">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-secondary">
                    <ImageWithBasePath
                      className="icon-img bg-secondary"
                      src="/assets/img/icons/booking.svg"
                      alt="Book Your Ride"
                    />
                  </div>
                  <div className="services-content">
                    <h3>1. Book Your Ride</h3>
                    <p>
                      Use our website or mobile app to choose your trip details.
                      Pick the date, time, and destination for your pilgrimage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-warning">
                    <ImageWithBasePath
                      className="icon-img bg-warning"
                      src="/assets/img/icons/confirmation.svg"
                      alt="Receive Confirmation"
                    />
                  </div>
                  <div className="services-content">
                    <h3>2. Receive Confirmation</h3>
                    <p>
                      Youll get a confirmation message with your drivers details and the estimated time of arrival.
                      This message ensures everything is set for your trip.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-dark">
                    <ImageWithBasePath
                      className="icon-img bg-dark"
                      src="/assets/img/icons/ejoy-ride.svg"
                      alt="Enjoy Your Journey"
                    />
                  </div>
                  <div className="services-content">
                    <h3>3. Enjoy Your Journey</h3>
                    <p>
                      Your driver will arrive at the scheduled time, ready to take you to your destination.
                      The ride is comfortable, safe, and respectful of your pilgrimage experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /services */}

      <section className="section facts-number">
        <div className="facts-left">
          <ImageWithBasePath
            src="/assets/img/bg/facts-left.png"
            className="img-fluid"
            alt="facts left"
          />
        </div>
        <div className="facts-right">
          <ImageWithBasePath
            src="/assets/img/bg/facts-right.png"
            className="img-fluid"
            alt="facts right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2 className="title text-white">Facts By The Numbers</h2>
            <p className="description text-white">
              stand as unshakable pillars of truth, showcasing the tangible milestones of our journey and the concrete impact we make.
            </p>
          </div>
          {/* /Heading title */}
          <div className="counter-group">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="/assets/img/icons/Good-review.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={16000}
                          duration={3}
                          separator=","
                        />
                        K<br />
                      </h4>
                      <p> Happy Customer </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="/assets/img/icons/Sedan-car-front.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={250}
                          duration={3}
                          separator=","
                        />
                        +<br />
                      </h4>
                      <p>Count of Cars</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="/assets/img/icons/Solution.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={625}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>Car Center Solutions</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="/assets/img/icons/Distance.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={200}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>Total Kilometer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="section why-choose">
        <div className="choose-left">
          <ImageWithBasePath
            src="/assets/img/bg/choose-left.png"
            className="img-fluid"
            alt="Why Choose Us"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Why Choose Us</h2>
            <p>
                Al-Haramain Cab Service offers dedicated transportation to pilgrims and travelers across Saudi Arabia,
                featuring a wide range of routes, a diverse vehicle fleet, and professional drivers.
                Known for high customer satisfaction, 24/7 operations, and multilingual support,
                the company emphasizes environmental sustainability and community involvement,
                establishing a notable industry presence.
            </p>
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-black">
                      <ImageWithBasePath
                        src="/assets/img/icons/easy.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Easy Fast Booking</h4>
                      <p>
                        Our booking process is designed to be both easy and fast, ensuring you can secure
                        our services with minimal hassle and maximum efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <ImageWithBasePath
                        src="/assets/img/icons/expert.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Expertise and Experience</h4>
                      <p>
                        Our team’s deep knowledge and years of experience ensure that we understand the details of
                        your requirements, delivering solutions that are both efficient and effective.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <ImageWithBasePath
                        src="/assets/img/icons/Trust.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Reliability and Trust</h4>
                      <p>
                        Built on a foundation of trust and reliability, we pride ourselves on being a partner
                        you can count on, delivering on our promises and ensuring your peace of mind.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us */}
      {/* About us Testimonials */}
      <section className="section about-testimonial testimonials-section">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2 className="title text-white">What People say about us? </h2>
            <p className="description text-white">
              Happy travelers say we make their special journey even better. They love how we take care of every detail,
              making their trip easy and peaceful.
              They&apos;re thankful for our kind and careful help, and they tell others we&apos;re a great choice for a worry-free journey.
            </p>
          </div>
          {/* /Heading title */}
          <div className="owl-carousel about-testimonials testimonial-group mb-0 owl-theme">
            {/* /Carousel Item  */}
            {/* Carousel Item */}
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="testimonial-item d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="quotes-head" />
                      <div className="review-box">
                        <div className="review-profile">
                          <div className="review-img">
                            <ImageWithBasePath
                              src={testimonial.image}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="review-details">
                          <h6>{testimonial.name}</h6>
                          <div className="list-rating">
                            <div className="list-rating-star">
                              {[...Array(Math.floor(testimonial.rating))].map(
                                (_, i) => (
                                  <i key={i} className="fas fa-star filled" />
                                )
                              )}
                            </div>
                            <p>
                              <span>({testimonial.rating})</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p>{testimonial.review}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            {/* /Carousel Item  */}
            {/* Carousel Item */}

            {/* /Carousel Item  */}
          </div>
        </div>
      </section>
      {/* About us Testimonials */}
      <>
        {/* FAQ  */}
        <section className="section faq-section bg-light-primary">
          <div className="container">
            {/* Heading title*/}
            <div className="section-heading" data-aos="fade-down">
              <h2>Frequently Asked Questions </h2>
            </div>
            {/* /Heading title */}
            <div className="faq-info">
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqOne"
                    aria-expanded="false"
                  >
                    How do I book my Umrah journey with you?
                  </Link>
                </h4>
                <div id="faqOne" className="card-collapse collapse">
                  <p>
                    You can book your journey through our website or mobile app. Simply select your desired package, fill in your details, and choose your payment option. A confirmation will be sent to you immediately after booking.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqTwo"
                    aria-expanded="false"
                  >
                    What types of packages do you offer for the Umrah journey?
                  </Link>
                </h4>
                <div id="faqTwo" className="card-collapse collapse">
                  <p>
                    We offer a variety of packages tailored to meet different needs and budgets, including basic, premium, and luxury options. Each package includes travel, accommodation, and visa processing, with detailed information available on our website.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqThree"
                    aria-expanded="false"
                  >
                    Can you accommodate special requests or needs?
                  </Link>
                </h4>
                <div id="faqThree" className="card-collapse collapse">
                  <p>
                    Yes, we strive to accommodate all special requests, including dietary restrictions, wheelchair accessibility, and any other personal needs. Please mention your requirements when booking, and we&apos;ll do our best to meet them.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqFour"
                    aria-expanded="false"
                  >
                    What is included in the cost of an Umrah package?
                  </Link>
                </h4>
                <div id="faqFour" className="card-collapse collapse">
                  <p>
                    Generally, our packages include visa processing, flights, hotel accommodation, transportation in Saudi Arabia, and meals. Specific details vary by package, so please refer to the package description or contact us for more information.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqFive"
                    aria-expanded="false"
                  >
                    How early should I book my Umrah journey?
                  </Link>
                </h4>
                <div id="faqFive" className="card-collapse collapse">
                  <p>
                    We recommend booking at least two weeks in advance, especially during peak seasons, to ensure the best prices and availability. However, we can accommodate last-minute bookings based on availability.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqSix"
                    aria-expanded="false"
                  >
                    Is there a guide or assistance available during the Umrah journey?
                  </Link>
                </h4>
                <div id="faqSix" className="card-collapse collapse">
                  <p>
                    Yes, our packages include the services of experienced guides who are knowledgeable about the Umrah rites and can provide assistance throughout your journey.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqSeven"
                    aria-expanded="false"
                  >
                    What is your cancellation policy?
                  </Link>
                </h4>
                <div id="faqSeven" className="card-collapse collapse">
                  <p>
                    Our cancellation policy varies by package. Typically, cancellations made within a certain period before departure may be eligible for a full or partial refund. Please review the terms and conditions of your package for detailed information.
                  </p>
                </div>
              </div>

              <div className="faq-card bg-white" data-aos="fade-down">
                  <h4 className="faq-title">
                   <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      href="#faqEight"
                      aria-expanded="false"
                    >
                      How do I prepare for Umrah?
                    </Link>
                  </h4>
                  <div id="faqEight" className="card-collapse collapse">
                    <p>
                      We provide all travelers with a comprehensive guide on how to prepare for Umrah, including necessary documents, recommended clothing, and spiritual preparation. Our team is also available to answer any questions you may have.
                    </p>
                  </div>
              </div>
            </div>

            <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqNine"
                    aria-expanded="false"
                  >
                    Are flights and accommodations flexible?
                  </Link>
                </h4>
                <div id="faqNine" className="card-collapse collapse">
                  <p>
                    Yes, we offer a range of flight and accommodation options to suit different preferences and budgets. You can choose your airline and hotel when booking your package.
                  </p>
                </div>
            </div>

            <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                 <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqTen"
                    aria-expanded="false"
                  >
                    How can I contact you for more questions?
                  </Link>
                </h4>
                <div id="faqTen" className="card-collapse collapse">
                  <p>
                    You can contact us through our website&apos;s contact form, email, or phone. Our customer service team is available 24/7 to assist you with any inquiries.
                  </p>
                </div>
            </div>
          </div>
        </section>
        {/* /FAQ */}
      </>
    </>
  );
};

export default About;
