import React from "react";
import { Link } from "react-router-dom";
import "rc-slider/assets/index.css";

interface LitingFlterProps{
  popularCartTypes:any,
  vehicleTypeList:any,
  onSelectCarCategory: (name: string) => void; 
}
const ListingSidebar = ({
  popularCartTypes,
  vehicleTypeList,
  onSelectCarCategory
}:LitingFlterProps) => {

  const handleCheckboxChange = (name: string) => {
    onSelectCarCategory(name); // Pass the selected name to parent component
  };

  return (
    <>
      <form action="#" autoComplete="off" className="sidebar-form">
        {/* Customer */}
        <div className="sidebar-heading">
          <h3>What Are You Looking For</h3>
        </div>
        <div className="accordion" id="accordionMain1">
          <div className="card-header-new" id="headingOne">
            <h6 className="filter-title">
              <Link
                to="#"
                className="w-100"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Car Category
                <span className="float-end">
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              </Link>
            </h6>
          </div>
          <div
            id="collapseOne"
            className="collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div className="card-body-chat">
              <div className="row">
                <div className="col-md-12">
                  <div id="checkBoxes1">
                    <div className="selectBox-cont">
                      {popularCartTypes.map((item:any, index:any) =>{
                        return<>
                         <label className="custom_check w-100" key={index}>
                        <input type="checkbox" name="username"
                         onChange={() => handleCheckboxChange(item.id)}
                          />
                        <span className="checkmark" /> {item.name}
                      </label>
                        </>
                      })}
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion" id="accordionMain2">
          <div className="card-header-new" id="headingTwo">
            <h6 className="filter-title">
              <Link
                to="#"
                className="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Vehicles Type
                <span className="float-end">
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              </Link>
            </h6>
          </div>
          <div
            id="collapseTwo"
            className="collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample2"
          >
            <div className="card-body-chat">
              <div className="row">
                <div className="col-md-12">
                  <div id="checkBoxes2">
                    <div className="selectBox-cont">
                      {vehicleTypeList.map((item:any, index:any) =>{
                        return<>
                         <label className="custom_check w-100" key={index}>
                        <input type="checkbox" name="username" />
                        <span className="checkmark" /> {item.name}
                      </label>
                        </>
                      })}
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
       
        <button
          className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary filter-btn"
        >
          <span>
            <i className="feather icon-filter me-2" />
          </span>
          Filter results
        </button>
        <Link to="" className="reset-filter">
          Reset Filter
        </Link>
      </form>
    </>
  );
};

export default ListingSidebar;
