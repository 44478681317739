// store.ts

import { configureStore } from '@reduxjs/toolkit';
import { persistedReducer } from '../../../persistenceConfig';

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
