'use client'
import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { all_routes } from '../../router/all_routes'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../core/data/redux/action'

const SettingsSidebar = () => {
    const route = all_routes
    const token = useSelector((state: any) => state.auth.token);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isLinkActive = (route:string) => {
        return location.pathname === route;
    };

    useEffect(()=>{
        if(token.length === 0) {
          navigate(route.home);
        }
      },[])
    const LogoutUser = () =>{
        dispatch(logoutUser());
        navigate(route.home);
    }

return (
        <div className="col-lg-3 theiaStickySidebar">
            <div className="stickybar">
                <div className="settings-widget">
                    <div className="settings-menu">
                        <ul>
                            <li>
                                <Link to={route.settings} className={isLinkActive(route.settings) ? "active" : ""}>
                                    <i className="feather-user" /> Profile
                                </Link>
                            </li>
                            <li onClick={LogoutUser}>
                                <Link to={''} className={isLinkActive(route.security) ? "active" : ""}>
                                    <i className="feather-shield" /> Logout
                                </Link>
                            </li>
                            {/* <li>
                                <Link to={route.security} className={isLinkActive(route.security) ? "active" : ""}>
                                    <i className="feather-shield" /> Security
                                </Link>
                            </li>
                            <li>
                                <Link to={route.preference} className={isLinkActive(route.preference) ? "active" : ""}>
                                    <i className="feather-star" /> Preferences
                                </Link>
                            </li>
                            <li>
                                <Link to={route.notification} className={isLinkActive(route.notification) ? "active" : ""}>
                                    <i className="feather-bell" /> Notifications
                                </Link>
                            </li>
                            <li>
                                <Link to={route.integration} className={isLinkActive(route.integration) ? "active" : ""}>
                                    <i className="feather-git-merge" /> Integration
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
)
}

export default SettingsSidebar
