'use client'
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../common/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Aos from "aos";
import DashboardMenu from "./dashboardmenu";
import { all_routes } from "../router/all_routes";
import { API_BASE_URL } from "../../environment";
import axios from "axios";
import { useSelector } from "react-redux";

const UserDashboard = () => {
  const route = all_routes
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.auth.token);
  const [userBookings, setUserBookings] = useState([]);

  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
   
  }, []);

  useEffect(()=>{
    if(token.length === 0) {
      navigate(route.home);
    }
  },[])

  useEffect(() => {
    if (token) {
      fetchUserBookings();
    }
  }, [token]);
  const fetchUserBookings = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/booking_list`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUserBookings(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  console.log(userBookings,'ssss')
  return (
    <>
      <Breadcrumbs title="User Dashboard" subtitle="User Dashboard" />
      <DashboardMenu />

      <div className="content dashboard-content">
        <div className="container">
    
          <div className="content-header">
            <h4>Dashboard</h4>
          </div>
          {/* /Content Header */}
          {/* Dashboard */}
          <div className="row">
            {/* Widget Item */}
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="widget-box flex-fill">
                <div className="widget-header">
                  <div className="widget-content">
                    <h6>My Bookings</h6>
                    <h3>{userBookings.length}</h3>
                  </div>
                  <div className="widget-icon">
                    <span>
                      <ImageWithBasePath
                        src="/assets/img/icons/book-icon.svg"
                        alt="icon"
                      />
                    </span>
                  </div>
                </div>
                <Link to={route.userbookings} className="view-link">
                  View all Bookings <i className="feather-arrow-right" />
                </Link>
              </div>
            </div>
            {/* Widget Item */}
            {/* <div className="col-lg-3 col-md-6 d-flex">
              <div className="widget-box flex-fill">
                <div className="widget-header">
                  <div className="widget-content">
                    <h6>Wishlist</h6>
                    <h3>0</h3>
                  </div>
                  <div className="widget-icon">
                    <span className="bg-danger">
                      <ImageWithBasePath
                        src="/assets/img/icons/cars-icon.svg"
                        alt="icon"
                      />
                    </span>
                  </div>
                </div>
                <Link to={route.wishlist} className="view-link">
                  Go to Wishlist <i className="feather-arrow-right" />
                </Link>
              </div>
            </div> */}
            {/* /Widget Item */}
          </div>
          <div className="row">
            {/* Last 5 Bookings */}
            <div className="col-lg-12 d-flex">
              <div className="card user-card flex-fill">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <h5>Last 5 Bookings</h5>
                    </div>
                    <div className="col-sm-5 text-sm-end">
                      <div className="booking-select">
                        <Link to={route.userbookings} className="view-link">
                          View all Bookings
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive dashboard-table dashboard-table-info">
                    <table className="table">
                      <tbody>
                        {userBookings?.map((booking:any,index)=>{
                          return(
                            <>
                            <tr key={index}>
                            <td>
                              <div className="table-avatar">
                                <Link
                                    to={``}
                                  className="avatar avatar-lg flex-shrink-0"
                                >
                                  <ImageWithBasePath
                                    className="avatar-img"
                                    src={booking?.package_detail?booking.package_detail.image:booking.vehicle_detail.image}
                                    alt="Booking"
                                  />
                                </Link>
                                <div className="table-head-name flex-grow-1">
                                  <Link   to={route.userbookings}>
                                   {booking?.package_detail?booking?.package_detail.name:booking?.vehicle_detail.name}
                                  </Link>
                                  <p>{booking?.package_detail?booking?.package_detail?.vehicle_detail?.vehicle_type?.name:booking?.vehicle_detail?.vehicle_type?.name} : {booking?.package_detail?booking?.package_detail.vehicle_detail?.passenger_capacity:booking?.vehicle_detail?.passenger_capacity} persons</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <h6>PickUp Location</h6>
                              <p>{booking?.package_detail?booking?.package_detail?.pickup_location?.name:booking.pickup_location?.name}</p>
                            </td>
                            <td>
                              <h6>Drop off Location</h6>
                              <p>{booking?.package_detail?booking?.package_detail?.pickup_location?.name:booking?.drop_location?.name}</p>
                            </td>
                            
                            <td>
                              <span className="badge badge-light-secondary">
                                {booking?.status?booking?.status:'In Progress'}
                              </span>
                            </td>
                          </tr>
                          </>
                            
                          )
                        })}
                       
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
          {/* /Dashboard */}
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
