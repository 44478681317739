import { combineReducers } from 'redux';
import { LOGIN_USER, LOGOUT_USER } from './action';
const initialState = {
  user: [],
  token:''
};


const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        token:action.payload.token

      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        token:''
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer
});

export default rootReducer;
